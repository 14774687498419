<template>
  <div>
    <b-card>
      <h4>Details</h4>

      <div class="d-flex p-1">
        <div class="w-100">
          <b-row>
            <b-col cols="3">
              Recipient
            </b-col>
            <b-col>
              {{ checkout.delivery_address.recipient }}
            </b-col>
          </b-row>

          <b-row>
            <b-col cols="3">
              Status
            </b-col>
            <b-col>
              {{ checkout.status }}
            </b-col>
          </b-row>

          <b-row>
            <b-col cols="3">
              Recipient Email
            </b-col>
            <b-col>
              {{ checkout.buyer }}
            </b-col>
          </b-row>

          <b-row>
            <b-col cols="3">
              Phone Number
            </b-col>
            <b-col>
              {{ checkout.delivery_address.phone_number }}
            </b-col>
          </b-row>
        </div>

        <!-- Delivery Details -->
        <div class="w-100">
          <b-row>
            <b-col cols="3">
              Delivery Address
            </b-col>
            <b-col>
              {{ checkout.delivery_address.address }}
            </b-col>
          </b-row>

          <b-row>
            <b-col cols="3">
              Delivery State
            </b-col>
            <b-col>
              {{ checkout.delivery_address.state }}
            </b-col>
          </b-row>

          <b-row>
            <b-col cols="3">
              Delivery Country
            </b-col>
            <b-col>
              {{ checkout.delivery_address.country }}
            </b-col>
          </b-row>

          <b-row>
            <b-col cols="3">
              Delivery Postal Code
            </b-col>
            <b-col>
              {{ checkout.delivery_address.postal_code }}
            </b-col>
          </b-row>
        </div>
      </div>
    </b-card>

    <div class="list-view product-checkout mt-0">

      <!-- Products List -->
      <e-commerce-checkout-step-cart-products
        :product-in-cart="this.productInCart"
        :is-editable="false"
        @refresh-cart="$emit('refresh-cart')"
      />

      <!-- Checkout Options -->
      <div class="checkout-options">
        <b-card>

          <label class="section-label mb-1">Update Status to :</label>
          <div class="text-center">
            <h6 v-if="checkout.status === 'Waiting Confirmation'"
                class="price-title">
              In Process
            </h6>
            <h6 v-if="checkout.status === 'In Process'"
                class="price-title">
                Ready to Ship
            </h6>
            <h6 v-if="checkout.status === 'Ready to Ship'"
                class="price-title">
                Ship
            </h6>
            <h6 v-if="checkout.status === 'Ship'"
                class="price-title">
                Delivered
            </h6>
            <hr>
            <b-button
              variant="primary"
              block
              @click="updateStatus(checkout.status)"
            >
            Proceed
            </b-button>
          </div>
           <hr>
           
        </b-card>
      </div>
    </div>
  </div> 
</template>

<script>
import ECommerceCheckoutStepCartProducts from '../MarketplaceCheckoutStepCartProducts.vue'

export default {
  components: {
    ECommerceCheckoutStepCartProducts,
  },
  data() {
    return {
      checkout: {},
      productInCart: [{ product : {} }],
      totalPrice: 0,
      subtotalPrice: 0,
    }
  },
  created() {
    this.get_checkout_details()
  },
  methods: {
    get_checkout_details() {
      const id = this.$route.params.id

      this.$http
        .get(`/api/marketplace/order/${id}`)
        .then(response => {
          const { success, output } = response.data

          if (success) {
            this.checkout = output
            this.subtotalPrice = this.checkout.price
            this.totalPrice = this.checkout.total_price

            this.productInCart.product = Object.keys(output.product)
            .map(function(key) {
                return output.product[key];
            });
            
          } else {
            this.productInCart = []
            this.subtotalPrice = 0
            this.totalPrice = this.subtotalPrice
          }
        })
        .catch(() => {})
    },
    
    updateStatus(status){
      
      let new_status = '';

      if (status == 'Waiting Confirmation'){
        new_status = 'In Process'
      } else if (status == 'In Process'){
        new_status = 'Ready to Ship'
      } else if (status == 'Ready to Ship'){
        new_status = 'Ship'
      } else if (status == 'Ship'){
        new_status = 'Delivered'
      }

      const post_data = new FormData()
      post_data.append('status',  new_status)
      post_data.append('order_id',  this.checkout._id)

      this.$http
            .post(`/api/edit-orders`, post_data)
            .then(response => {
              const { success } = response.data
              const { message } = response.data
              this.isLoading = false
              if (success) {
                this.$toast(
                  {
                    component: this.$toastContent,
                    props: {
                      title: 'Orders Notification',
                      icon: 'AlertCircleIcon',
                      text: message,
                      variant: 'success',
                    },
                  },
                  { timeout: this.$longestTimeout },
                )
                this.$router.push('/marketplace-orders')
              } else {
                this.$toast(
                  {
                    component: this.$toastContent,
                    props: {
                      title: 'Orders Notification',
                      icon: 'AlertCircleIcon',
                      text: `Can't update Order, try again later`,
                      variant: 'error',
                    },
                  },
                  { timeout: this.$longestTimeout },
                )
              }
            })
            .catch(error => {
              alert('ERROR')
            })
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-wizard.scss';
@import '~@core/scss/base/pages/app-ecommerce.scss';
@import '~vue-form-wizard/dist/vue-form-wizard.min.css';
</style>
